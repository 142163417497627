import UserList from "../View/User/UserList/UserList";
import CreateUser from "../View/User/CreateUser/CreateUser";
import CreateProject from "../View/Project/CreateProject/CreateProject";
import ProjectList from "../View/Project/ProjectList/ProjectList";
import CompanyList from "../View/Company/CompanyList/CompanyList";
import CreateCompany from "../View/Company/CreateCompany/CreateCompany";
import AccountSetting from "../View/AccountSetting/AccountSetting";


// import NewReport from "../View/Report/NewReport";

const AdminRoute = [
     
      
      {
        path: "/createcompany",
        component: <CreateCompany /> ,
      },
      {
        path: "/companylist",   
        component: <CompanyList /> ,
      },
      
      {
        path: "/account",   
         component: <AccountSetting /> ,
      },

 
];

export default AdminRoute;
