import React, { Fragment, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Colors } from "../../Assets/Styles/Colors";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import ProjectServices from "../../Api/ProjectServices/Project.index";
import UserServices from "../../Api/UserServices/User.index";
import { ErrorToaster, SuccessToaster } from "../../Components/Toaster/Toaster";
import Storage from "../../Utils/Storage";
import { jwtDecode } from "jwt-decode";
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ReportServices from "../../Api/Report/Report.index";
import { ErrorHandler } from "../../Utils/ErrorHandler";
import { useNavigate } from "react-router-dom";
function Form() {
  // Get current date
  const { setStorageItem, getStorageItem } = Storage();
  const currentDate = moment().format("YYYY-MM-DD");
  // const currentDate = new Date().toISOString().split("T")[0];

  // Function to format date and day
  // const formatDateAndDay = (date) => {
  //   return moment(date).format("D/MMM/YYYY dddd"); // Example format: 3/May/2024 Monday
  // };

  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [selectWorkType, setSelectWorkType] = useState("onSite");
  const [limit, setLimit] = useState(10);
  const [getProject, setGetProject] = useState([
    { project: "", hour_count: "" },
  ]);
  console.log("🚀 ~ Form ~ getProject:", getProject);
  const [projectList, setProjectList] = useState([]);
  const [user, setUser] = useState(getStorageItem("user"));

  const decodedToken = jwtDecode(user.token);
  const userIdFromToken = decodedToken.id;
  const userId = userIdFromToken;

  const navigate = useNavigate();
  // console.log(userId)
  const {
    register,
    setError,
    clearErrors,
    handleSubmit,
    setValue,
    reset,
    resetField,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      projects: getProject,
    },
  });
  console.log("🚀 ~ Form ~ errors:", errors);

  // Function to get day from the date
  const getDayFromDate = (dateString) => {
    const date = new Date(dateString);
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return days[date.getDay()];
  };

  const addProjectField = () => {
    setGetProject([...getProject, { project: "", hour_count: "" }]);
  };

  const removeProjectField = (index) => {
    const updatedProjects = [...getProject];
    updatedProjects.splice(index, 1);
    setGetProject(
      updatedProjects.length > 0
        ? updatedProjects
        : [{ project: "", hour_count: "" }]
    );
  };

  const handleProjectChange = (value, index) => {
    const isAlreadySelected = getProject.some(
      (project, i) => project.project === value && i !== index
    );
    if (isAlreadySelected) {
      console.log("Project already selected in another field!");
    } else {
      const updatedProjects = [...getProject];
      updatedProjects[index].project = value;
      setGetProject(updatedProjects);
    }
  };

  const isProjectDisabled = (projectId, currentIndex) => {
    return getProject.some(
      (project, index) =>
        project.project === projectId && index !== currentIndex
    );
  };

  const handleHourCountChange = (value, index) => {
    const updatedProjects = [...getProject];
    updatedProjects[index].hour_count = value;
    setGetProject(updatedProjects);
  };
  ///get PRojects
  const getAllProjects = async (
    page,
    limit,
    search,
    user,
    priority,
    status
  ) => {
    const { data, responseCode } = await ProjectServices.getProject(
      page,
      limit,
      search,
      user,
      priority ? priority : "",
      status ? status : ""
    );
    try {
      setProjectList(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (userId) {
      getAllProjects(1, 1000, "", userId, "", "");
    }
  }, []);

  const handleDateChange = (date) => {
    const formattedDate = moment(date).format("DD MMM YYYY ,dddd");
    setSelectedDate(date);
    setValue("date", formattedDate);
  };

  const CustomInput = ({ value, onClick }) => (
    <TextField
      fullWidth
      sx={{ width: "100%" }}
      value={value}
      onClick={onClick}
      readOnly
      InputProps={{
        startAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={onClick}>
              <DateRangeIcon />
            </IconButton>
          </InputAdornment>
        ),
        ...register("date", { required: true }),
      }}
      error={!!errors.date}
      helperText={errors.date ? "Date is required" : ""}
    />
  );
  useEffect(() => {
    for (let index = 0; index < getProject.length; index++) {
      const watchProjects = watch(`projects[${index}].project`);
      if (watchProjects !== getProject[index].project) {
        setValue(`projects[${index}].project`, getProject[index].project);
      }
    }
  }, [getProject, setValue]);

  const onSubmit = async (formData) => {
    console.log("🚀 ~ onSubmit ~ formData:", formData);
    const projectsData = getProject?.map((projects) => ({
      _id: projects.project,
      hour: projects.hour_count,
    }));

    const obj = {
      date: moment(selectedDate).format("YYYY-MM-DD"),
      day: getDayFromDate(selectedDate),
      workType: selectWorkType,
      projectIds: projectsData,
      // projectIds: getProject?.map((elem) => elem?.project),
      comments: formData.comments,
    };

    console.log(obj);
    try {
      const { data, responseCode, message } = await ReportServices.UpdateStats(
        userId,
        obj
      );
      SuccessToaster("Your response has been submitted");

      reset({
        projects: [{ project: "", hour_count: "" }],
        comments: "",
      });
      // Reset the state
      setGetProject([{ project: "", hour_count: "" }]);
      navigate("/progress");
      // console.log(data);
    } catch (error) {
      ErrorHandler(error);
      ErrorToaster(error?.message);
      console.log(error);
    }
  };

  return (
    <>
      <Box sx={{ mt: 5 }}>
        <Box
          sx={{
            backgroundColor: Colors.primary,
            borderRadius: "10px",
            minHeight: "50vh",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            textAlign: "center",
            width: "95%",
            margin: "0 auto",
            color: Colors.black,
            position: "relative",
          }}
        >
          <Box sx={{ pt: 3 }}>
            <Box>
              <h2>Developer Management Form</h2>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: Colors.smokeWhite,
          boxShadow: "rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem",
          p: 2,
          position: "absolute",
          // top: {
          //   xs: "55%",
          //   sm: "55%",
          //   md: "57%",
          // },
          top: {
            xs: "400px",
            sm: "400px",
            md: "450px",
          },
          width: "85%",
          right: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "10px",
          overflow: "auto",
          height: "450px",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            sx={{ mt: 2, alignItems: "flex-start" }}
            container
            spacing={2}
            alignItems="center"
          >
            {/* Date */}
            <Grid item xs={12} sm={6}>
              <InputLabel>Date</InputLabel>
              <ReactDatePicker
                sx={{ width: "100%" }}
                selected={selectedDate}
                onChange={handleDateChange}
                customInput={
                  <CustomInput
                    sx={{ width: "100%" }}
                    value={
                      selectedDate
                        ? moment(selectedDate).format("DD MMM YYYY ,dddd  ")
                        : ""
                    }
                  />
                }
                dateFormat="dd MMM yyyy ,eeee"
                minDate={moment().subtract(2, "days").toDate()}
                // minDate={new Date()}
                maxDate={new Date()}
              />
              {errors.date && (
                <Typography variant="caption" color="error">
                  Date is required
                </Typography>
              )}
            </Grid>
            {/* Select Work Type */}
            <Grid item xs={12} sm={6}>
              <InputLabel>Select Work Type</InputLabel>
              <TextField
                fullWidth
                select
                {...register("workType", { required: true })}
                value={selectWorkType}
                onChange={(e) => setSelectWorkType(e.target.value)}
              >
                <MenuItem value="onSite">On-Site</MenuItem>
                <MenuItem value="wfh">Work From Home</MenuItem>
              </TextField>
              {errors.workType && (
                <Typography variant="caption" color="error">
                  Work Type is required
                </Typography>
              )}
            </Grid>
            {getProject?.map((project, index) => (
              <Fragment key={index}>
                <Grid item xs={12} sm={12}>
                  {index !== 0 && (
                    <Box
                      onClick={() => removeProjectField(index)}
                      sx={{
                        display: "flex",
                        float: "right",
                        padding: "8px 15px",
                        textAlign: "center",
                        color: Colors.white,
                        borderRadius: "50%",
                        backgroundColor: Colors.primary,
                        cursor: "pointer",
                      }}
                    >
                      -
                    </Box>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <InputLabel>Select Project</InputLabel>
                  <Controller
                    name={`projects[${index}].project`}
                    control={control}
                    rules={{ required: "Project is required" }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={projectList?.output?.projects || []}
                        getOptionLabel={(option) => option.name}
                        value={
                          projectList?.output?.projects.find(
                            (item) => item._id === field.value
                          ) || null
                        }
                        onChange={(e, newValue) => {
                          handleProjectChange(newValue?._id, index); 
                          field.onChange(newValue?._id);
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option._id === value
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            placeholder="Search project"
                            error={!!errors.projects?.[index]?.project}
                            helperText={
                              errors.projects?.[index]?.project?.message || ""
                            }
                          />
                        )}
                        getOptionDisabled={(option) =>
                          isProjectDisabled(option._id, index)
                        } 
                        ListboxProps={{
                          style: {
                            maxHeight: 200, 
                            overflowY: "auto", 
                          },
                        }}
                      />
                    )}
                  />
                  {/* {errors.projects && errors.projects[index]?.project && (
                    <Typography variant="caption" color="error">
                      {errors.projects[index].project.message}
                    </Typography>
                  )} */}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <InputLabel>No of Working Hours</InputLabel>
                  <TextField
                    type="text"
                    fullWidth
                    value={project.hour_count}
                    {...register(`projects[${index}].hour_count`, {
                      required: "Working hours are required",
                      pattern: {
                        value: /^\d*(\.\d{0,2})?$/,
                        message:
                          "Invalid format. Use only numbers with up to two decimal places",
                      },
                    })}
                    onChange={(e) => {
                      handleHourCountChange(e.target.value, index);
                      const input = e.target.value;
                      if (!input.match(/^\d*(\.\d{0,2})?$/)) {
                        setError(`projects[${index}].hour_count`, {
                          type: "pattern",
                          message:
                            "Invalid format. Use only numbers with up to two decimal places",
                        });
                      } else {
                        clearErrors(`projects[${index}].hour_count`);
                      }
                    }}
                  />
                  {errors.projects && errors.projects[index]?.hour_count && (
                    <Typography variant="caption" color="error">
                      {errors.projects[index].hour_count.message}
                    </Typography>
                  )}
                </Grid>
              </Fragment>
            ))}
            <Grid item xs={12} sm={12}>
              <Box
                onClick={addProjectField}
                sx={{
                  p: 1,
                  width: "19px",
                  textAlign: "center",
                  color: Colors.white,
                  borderRadius: "50%",
                  backgroundColor: Colors.primary,
                  fontSize: "14px",
                  cursor: "pointer",
                  opacity:
                    getProject?.length >= projectList?.output?.projects?.length
                      ? 0.5
                      : 1,
                  pointerEvents:
                    getProject?.length >= projectList?.output?.projects?.length
                      ? "none"
                      : "auto",
                }}
              >
                +
              </Box>
            </Grid>
            {/* Comments */}
            <Grid item xs={12} sm={12}>
              <InputLabel>Comments (optional)</InputLabel>
              <TextField type="text" fullWidth {...register("comments")} />
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12} sm={12}>
              <Button
                type="submit"
                sx={{
                  display: "flex",
                  margin: "0 auto",
                  background: Colors.primary,
                  color: Colors.black,
                  padding: "5px 25px",
                  "&:hover": {
                    background: Colors.primary,
                  },
                }}
              >
                SUBMIT
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}

export default Form;
