import UserList from "../View/User/UserList/UserList";
import CreateUser from "../View/User/CreateUser/CreateUser";
import CreateProject from "../View/Project/CreateProject/CreateProject";
import ProjectList from "../View/Project/ProjectList/ProjectList";
import AccountSetting from "../View/AccountSetting/AccountSetting";
import CompanyList from "../View/Company/CompanyList/CompanyList";
import CreateCompany from "../View/Company/CreateCompany/CreateCompany";
import Report from "../View/Report/Report";
import CreateAdmin from "../View/Admin/CreateAdmin/CreateAdmin";
import AdminList from "../View/Admin/AdminList/AdminList";
import Dashboard from "../View/Dashboard/Dashboard";
const AdminRoute = [
  {
    path: "/dashboard",
    component: <Dashboard /> ,
  },
      {
        path: "/createuser",
        component: <CreateUser /> ,
      },
      {
        path: "/userlist",   
        component: <UserList /> ,
      },
      // {
      //   path: "/createadmin",
      //   component: <CreateAdmin /> ,
      // },
      // {
      //   path: "/adminlist",   
      //   component: <AdminList /> ,
      // },
      {
        path: "/createproject",
        component: <CreateProject /> ,
      },
      {
        path: "/projectlist",   
        component: <ProjectList /> ,
      }, 
      {
        path: "/report",   
         component: <Report /> ,
      },
      {
        path: "/account",   
         component: <AccountSetting /> ,
      },
    
 
];

export default AdminRoute;
