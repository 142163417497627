import { ReportRoutes } from "./Report.route";
import { post, get, put,deleted } from "../index";

const ReportServices = {
  getStats: async (userId ,projectId ) => {
    let result = get(ReportRoutes.getStats +`?user_id=${userId}&project_id=${projectId}`);
    return result;
  },
  getProgress: async (userId ) => {
    let result = get(ReportRoutes.getProgress +`?user_id=${userId}`);
    return result;
  },
  UpdateStats: async (id , obj) => {
    let result = put(ReportRoutes.UpdateStats + `/${id}` ,obj);
    return result;
  },
  deleteReport: async (id) => {
    let result = deleted(ReportRoutes.deleteReport + `?id=${id}`);
    return result;
  },
};

export default ReportServices;
