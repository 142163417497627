import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Colors } from "../../../Assets/Styles/Colors";
import { useForm } from "react-hook-form";
import CompanyServices from "../../../Api/CompanyServices/Company.index";
import ImageService from "../../../Api/ImageUploadService/ImageService.index";
import { ErrorToaster, SuccessToaster } from "../../../Components/Toaster/Toaster"
import { useNavigate } from "react-router-dom";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
  LanguageSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { ErrorHandler } from "../../../Utils/ErrorHandler";
function Form() {
  const [imageURL, setImageURL] = useState("")
  const [countryid, setCountryid] = useState("");
  const [stateid, setstateid] = useState("");
  const [selectStatus, setSelectStatus] = useState("active");
  const navigate = useNavigate()

  // React Hook Form
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

 
  // Function to handle form submission
  const onSubmit = async (formData) => {

    const obj ={
        name: formData.name,
        email:formData.email,
        phone:formData.phone,
        image: imageURL,
        address: formData.address,
        country: formData?.country?.name?formData?.country?.name:"",
        state: formData?.state?.name?formData?.state?.name:"nil",
        city: formData?.city?.name ?formData?.city?.name:"nil",
        website: formData?.website,
        link: formData?.link,
        status:formData?.status
    }
    console.log(obj)
    try {
      const { data, responseCode ,message} = await CompanyServices.createCompany(obj);
      SuccessToaster(message)
      navigate("/companyList")
       console.log(data)
    }catch(error){   
      ErrorHandler(error)
      ErrorToaster(error?.message);
      console.log(error); // Handle form submission here
    }

  };
  const handleImageUpload = async (e) => {
    try {
      const formData = new FormData();
      formData.append("image", e.target.files[0]);
      const response = await ImageService.imageUpload(formData);
      console.log(response);
      setImageURL(response.data.imageUrl);
    } catch (error) {
      console.error("Error uploading image: ", error);
    }
  };
  return (
    <>
      <Box sx={{ mt: 5 }}>
        <Box
          sx={{
            backgroundColor: Colors.primary ,  
            borderRadius: "10px",
            minHeight: "50vh",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            textAlign: "center",
            width: "95%",
            margin: "0 auto",
            color: Colors.black,
            position: "relative",
          }}
        >
          <Box sx={{ pt: 3 }}>
            <Box>
              <h1>Create Company </h1>
            </Box>
          </Box>
        </Box>
       
      </Box>
      <Box
        sx={{
          backgroundColor: Colors.smokeWhite,
          boxShadow: "rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem",
          p: 2,
          // m: 2,
          position: "absolute",
          top: {
            xs: "500px",
            sm: "500px",
            md: "500px  ",
          },  
          // top: {
          //   xs: "85%",
          //   sm: "60%",
          //   md: "60%",
          // },
          width: "85%",
          right: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "10px",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid sx={{ mt: 2,alignItems: "flex-start" }} container spacing={2} alignItems="center">
            {/* Name */}
            <Grid item xs={12} sm={6}>
              <InputLabel>Name</InputLabel>
              <TextField
                type="text"
                fullWidth
                {...register("name", { required: true })}
              />
              {errors.name && (
                <Typography variant="caption" color="error">
                  Name is required
                </Typography>
              )}
            </Grid>

            {/* Email */}
            <Grid item xs={12} sm={6}>
              <InputLabel>Email</InputLabel>
              <TextField
                type="email"
                fullWidth
                {...register("email", { required: true })}
              />
              {errors.email && (
                <Typography variant="caption" color="error">
                  Email is required
                </Typography>
              )}
            </Grid>
            {/* Phone */}
            <Grid item xs={12} sm={6}>
              <InputLabel>Phone Number</InputLabel>
              <TextField
                type="phone"
                fullWidth
                {...register("phone", { required: true })}
              />
              {errors.phone && (
                <Typography variant="caption" color="error">
                 Phone number is required
                </Typography>
              )}
            </Grid>

            {/* Phone */}
            {/* <Grid item xs={12} sm={6}>
              <InputLabel>Phone Number</InputLabel>
              <TextField
                type="text"
                fullWidth
                {...register("phone", { required: true })}
              />
              {errors.phone && (
                <Typography variant="caption" color="error">
                  phone number is required
                </Typography>
              )}
            </Grid> */}
            {/* Comments */}
          

            {/* Image */}
            <Grid item xs={12} sm={6}>
              <InputLabel>Image </InputLabel>
              <TextField
                type="file"
                fullWidth
                {...register("image", { required: true  })}
                onChange={(e) => {handleImageUpload(e)}}
                inputProps={{ accept: 'image/*' }}
              />
              {errors.image && (
                <Typography variant="caption" color="error">
                  Image is required
                </Typography>
              )}
            </Grid>
            {/* Address */}

            

            <Grid item xs={12} sm={6}>
          <InputLabel>Country</InputLabel>
          <CountrySelect
            onChange={(e) => {
              setCountryid(e.id);
              setValue("country", e);
            }}
           
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel>State</InputLabel>
          <StateSelect
            countryid={countryid}
            onChange={(e) => {
              setstateid(e.id);
              setValue("state", e);
            }}
          
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel>City</InputLabel>
          <CitySelect
          
            countryid={countryid}
            stateid={stateid}
            onChange={(e) => {
              setValue("city", e);
              console.log(e);
            }}
      
          />
        </Grid>
        <Grid item xs={12} sm={6}>
              <InputLabel>Address</InputLabel>
              <TextField
                type="text"
                fullWidth
                {...register("address", { required: true })}
              />
              {errors.address && (
                <Typography variant="caption" color="error">
                  Address is required
                </Typography>
              )}
            </Grid>
            {/* Country */}

            {/* <Grid item xs={12} sm={6}>
              <InputLabel>Country</InputLabel>
              <TextField
                type="text"
                fullWidth
                {...register("country", { required: true })}
              />
              {errors.country && (
                <Typography variant="caption" color="error">
                  Country is required
                </Typography>
              )}
            </Grid> */}
            {/* State */}

            {/* <Grid item xs={12} sm={6}>
              <InputLabel>State</InputLabel>
              <TextField
                type="text"
                fullWidth
                {...register("state", { required: true })}
              />
              {errors.state && (
                <Typography variant="caption" color="error">
                 State is required
                </Typography>
              )}
            </Grid> */}
            {/* City */}

            {/* <Grid item xs={12} sm={6}>
              <InputLabel>City</InputLabel>
              <TextField
                type="text"
                fullWidth
                {...register("city", { required: true })}
              />
              {errors.city && (
                <Typography variant="caption" color="error">
                 City is required
                </Typography>
              )}
            </Grid> */}
            {/* Website */}
            <Grid item xs={12} sm={6}>
              <InputLabel>Status</InputLabel>
              <TextField
                fullWidth
                select
                {...register("status", { required: true })}
                value={selectStatus}
                onChange={(e) => setSelectStatus(e.target.value)}
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">InActive</MenuItem>
               
               
              </TextField>
             
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel>Website</InputLabel>
              <TextField
                type="text"
                fullWidth
                {...register("website", { required: true })}
              />
              {errors.website && (
                <Typography variant="caption" color="error">
                 Website is required
                </Typography>
              )}
            </Grid>
            {/* Link */}

            <Grid item xs={12} sm={6}>
              <InputLabel>Link</InputLabel>
              <TextField
                type="text"
                fullWidth
                {...register("link", { required: true })}
              />
              {errors.link && (
                <Typography variant="caption" color="error">
                 Link is required
                </Typography>
              )}
            </Grid>
          
            {/* Submit Button */}
            <Grid item xs={12} sm={12}>
              <Button
                type="submit"
                sx={{
                  display:"flex",
                  margin:"0 auto",
                  background:
                    Colors.primary,
                  color: Colors.black,
                  padding: "5px 25px",
                  "&:hover": {
                    background:
                      Colors.primary,
                  },
                }}
              >
                SUBMIT
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}

export default Form;
