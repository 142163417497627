import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Colors } from "../../../Assets/Styles/Colors";
import { Controller, useForm } from "react-hook-form";
import ProjectServices from "../../../Api/ProjectServices/Project.index";
import FileServices from "../../../Api/FileUploadServices/FileUpload.index";
import {
  ErrorToaster,
  SuccessToaster,
} from "../../../Components/Toaster/Toaster";
import UserServices from "../../../Api/UserServices/User.index";
import MultiSelectAutocomplete from "../../../Components/MultSelectInput/MultiSelectInput";
import { useNavigate } from "react-router-dom";
import { ErrorHandler } from "../../../Utils/ErrorHandler";
import Storage from "../../../Utils/Storage";
import { jwtDecode } from "jwt-decode";

function Form() {
  const [selectStatus, setSelectStatus] = useState("active");
  const [selectPriority, setSelectPriority] = useState("medium");
  const [fileURL, setFileURL] = useState("");
  const [names, setNames] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  const [personName, setPersonName] = useState([]);

  const { setStorageItem, getStorageItem } = Storage();
  const [user, setUser] = useState(getStorageItem("user"));

  const decodedToken = jwtDecode(user.token);
  const userIdFromToken = decodedToken.id;
  const company_id = userIdFromToken;

  // React Hook Form
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const handleFileUpload = async (e) => {
    try {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const response = await FileServices.FileUpload(formData);
      console.log(response?.data?.fileUrl);
      setFileURL(response?.data?.fileUrl);
    } catch (error) {
      console.error("Error uploading image: ", error);
    }
  };

  // Function to handle form submission
  const onSubmit = async (formData) => {
    const obj = {
      assignedUsers: personName.map(userObj => ({
        user: userObj?._id,
        hour: 0
    })),
      // assignedManager: formData.assignedManager?.name,
      // assignedSupervisor: formData.assignedSupervisor?.name,
      assignedManager: formData.assignedManager,
      assignedSupervisor: formData.assignedSupervisor,
      name: formData.name,
      description: formData.description,
      startDate: formData.startDate,
      comments: formData.comments,
      status: formData.status,
      priority: formData.priority,
      endDate: formData.endDate,
      file: fileURL,
      clientDetails: formData.clientDetails,
    };
 console.log(obj)
    if (obj.assignedUsers.length == 0) {
      delete obj.assignedUsers;
    }

    console.log(obj);
    try {
      const { data, responseCode, message } =
        await ProjectServices.createProject(obj);
      SuccessToaster(message);
      navigate("/projectList");
    } catch (error) {
      ErrorHandler(error)
      ErrorToaster(error?.message);
      console.log(error);
    }
  };
  const handleGetUsers = async (page, limit, search, status,companyId) => {
    try {
      const { data, responseCode, message } = await UserServices.getUser(
        page,
        limit,
        search,
        status ? status : "",
        companyId?companyId:""
      );
      const filteredName = data?.output?.users.filter(
        (email) => email.role !== "admin" && email.role !== "company"
      );
      setNames(filteredName);
      setTotalCount(data?.output?.totalUsers);
      console.log(data);
    } catch (error) {
      console.error("Error while fetching users:", error);
    }
  };

  useEffect(() => {
    handleGetUsers(1, 1000, "", "",company_id);
  }, []);
  return (
    <>
      <Box sx={{ mt: 5 }}>
        <Box
          sx={{
            backgroundColor: Colors.primary,
            borderRadius: "10px",
            minHeight: "50vh",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            textAlign: "center",
            width: "95%",
            margin: "0 auto",
            color: Colors.black,
            position: "relative",
          }}
        >
          <Box sx={{ pt: 3 }}>
            <Box>
              <h1>Create Project</h1>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: Colors.smokeWhite,
          boxShadow: "rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem",
          p: 2,
          // m: 2,
          position: "absolute",
          top: {
            xs: "500px",
            sm: "500px",
            md: "500px  ",
          },
          // top: {
          //   xs: "100%",
          //   sm: "65%",
          //   md: "65%",
          // },
          width: "85%",
          right: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "10px",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            sx={{ mt: 2, alignItems: "flex-start" }}
            container
            spacing={2}
            alignItems="center"
          >
            {/* Name */}
            <Grid item xs={12} sm={6}>
              <InputLabel>Name</InputLabel>
              <TextField
                type="text"
                fullWidth
                {...register("name", { required: true })}
              />
              {errors.name && (
                <Typography variant="caption" color="error">
                  Name is required
                </Typography>
              )}
            </Grid>

            {/* Email */}
            <Grid item xs={12} sm={6}>
              <InputLabel>Description</InputLabel>
              <TextField
                type="text"
                fullWidth
                {...register("description", { required: true })}
              />
              {errors.description && (
                <Typography variant="caption" color="error">
                  Description is required
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel>Assign Users</InputLabel>

              <MultiSelectAutocomplete
                names={names}
                onClick={handleGetUsers}
                sx={{ width: "100%" }}
                personName={personName}
                setPersonName={setPersonName}
              />
            </Grid>

            {/* Assigned User */}
            {/* <Grid item xs={12} sm={6}>
              <InputLabel>Assigned User</InputLabel>
              <TextField
                type="text"
                fullWidth
                {...register("assignedUser")}
              />
            </Grid> */}
            {/* Created At */}
            {/* <Grid item xs={12} sm={6}>
              <InputLabel>Created At</InputLabel>
              <TextField
                type="date"
                fullWidth
                {...register("createdAt")}
              />
            </Grid> */}
            {/* DeadLine */}
            <Grid item xs={12} sm={6}>
              <InputLabel>Start Date</InputLabel>
              <TextField type="date" fullWidth {...register("startDate")} />
              {errors.startDate && (
                <Typography variant="caption" color="error">
                  Start Date is required
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
            <InputLabel>Assign Manager</InputLabel>
            <TextField
                type="text"
                fullWidth
                {...register("assignedManager",
                //  { required: true }
                )}
              />
              {/* {errors.assignedManager && (
                <Typography variant="caption" color="error">
                  Assigned Manager is required
                </Typography>
              )} */}
            </Grid>
            <Grid item xs={12} sm={6}>
            <InputLabel>Assign Supervisor</InputLabel>
            <TextField
                type="text"
                fullWidth
                {...register("assignedSupervisor",
                //  { required: true }
                )}
              />
              {/* {errors.assignedSupervisor && (
                <Typography variant="caption" color="error">
                  Assigned Supervisor is required
                </Typography>
              )} */}
            </Grid>
            {/* <Grid item xs={12} sm={6}>
        <InputLabel>Assign Manager</InputLabel>
        <Controller
          name="assignedManager"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              options={names}
              getOptionLabel={(option) => option.name || ""}
              // isOptionEqualToValue={(option, value) => option.id === value.id}
              
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  error={!!errors.assignedManager}
                  helperText={errors.assignedManager ? "Manager is required" : ""}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              onChange={(_, data) => field.onChange(data)}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <InputLabel>Assign Supervisor</InputLabel>
        <Controller
          name="assignedSupervisor"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              options={names}
              getOptionLabel={(option) => option.name || ""}
              // isOptionEqualToValue={(option, value) => option.id === value.id}
              
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  error={!!errors.assignedSupervisor}
                  helperText={errors.assignedSupervisor ? "Supervisor is required" : ""}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              onChange={(_, data) => field.onChange(data)}
            />
          )}
        />
      </Grid> */}
            {/* Status  */}
            <Grid item xs={12} sm={6}>
              <InputLabel>Status</InputLabel>
              <TextField
                fullWidth
                select
                {...register("status", { required: true })}
                value={selectStatus}
                onChange={(e) => setSelectStatus(e.target.value)}
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">InActive</MenuItem>
                <MenuItem value="onhold">on Hold</MenuItem>
                <MenuItem value="completed">Completed</MenuItem>
              </TextField>
            </Grid>
            {/* Priority  */}
            <Grid item xs={12} sm={6}>
              <InputLabel>Priority</InputLabel>
              <TextField
                fullWidth
                select
                {...register("priority", { required: true })}
                value={selectPriority}
                onChange={(e) => setSelectPriority(e.target.value)}
              >
                <MenuItem value="low">Low</MenuItem>
                <MenuItem value="medium">Medium</MenuItem>
                <MenuItem value="high">High</MenuItem>
              </TextField>
            </Grid>

            {/* End Date */}
            <Grid item xs={12} sm={6}>
              <InputLabel>End Date</InputLabel>
              <TextField type="date" fullWidth {...register("endDate")} />
              {errors.endDate && (
                <Typography variant="caption" color="error">
                  End Date is required
                </Typography>
              )}
            </Grid>
            {/* File */}

            <Grid item xs={12} sm={6}>
              <InputLabel>File</InputLabel>
              <TextField
                type="file"
                fullWidth
                {...register("file")}
                onChange={(e) => {
                  handleFileUpload(e);
                }}
              />
            </Grid>
            {/* Client Details */}
            <Grid item xs={12} sm={6}>
              <InputLabel>Client Details</InputLabel>
              <TextField type="text" fullWidth {...register("clientDetails")} />
              {errors.clientDetails && (
                <Typography variant="caption" color="error">
                  Client Details is required
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel>Comments (optional)</InputLabel>
              <TextField type="text" fullWidth {...register("comments")} />
            </Grid>
            {/* Submit Button */}
            <Grid item xs={12} sm={12}>
              <Button
                type="submit"
                sx={{
                  display: "flex",
                  margin: "0 auto",
                  background: Colors.primary,
                  color: Colors.black,
                  padding: "5px 25px",
                  "&:hover": {
                    background: Colors.primary,
                  },
                }}
              >
                SUBMIT
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}

export default Form;
