import React, { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import { Colors } from "../../Assets/Styles/Colors";
import dayjs from "dayjs";
import { Images } from "../../Assets/Images/Images";
import ReportServices from "../../Api/Report/Report.index";
import { jwtDecode } from "jwt-decode";
import Storage from "../../Utils/Storage";
import "rsuite/Calendar/styles/index.css";
import "rsuite/dist/rsuite-no-reset.min.css";
import { Calendar, Whisper, Popover, Badge, Icon } from "rsuite";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { PickersDay } from "@mui/x-date-pickers";

function getTodoList(date, statsData) {
  const dateString = dayjs(date).format("YYYY-MM-DD");
  return statsData.flatMap((user) =>
    user.projects.flatMap((project) =>
      project.days
        .filter((dayItem) => dayItem.date === dateString)
        .flatMap((day) =>
          (day.time.length > 0 ? day.time : [0]).map((time, index) => ({
            time: time || "0", // Handle empty time entries
            title: project.project_name.name,
            hours: `${day.hour[index]} hours`,
          }))
        )
    )
  );
}

const CalendarComponent = ({
  statsData,
  selectedDate,
  setSelectedDate,
  handleDateClick,
}) => {
  const renderCell = (date) => {
    const list = getTodoList(date, statsData);
    const moreItem = list.length ? (
      <Box key="more">
        <Whisper
          placement="top"
          trigger="click"
          speaker={
            <Popover
              style={{
                overflowY: list.length < 4 ? "hidden" : "auto",
                height: list.length > 4 ? "200px" : "auto",
              }}
            >
              {list.map((item, index) => (
                <p key={index}>
                  <b>{item.hours} -</b>
                  {item.title}
                </p>
              ))}
            </Popover>
          }
        >
          <a style={{ fontSize: "10px" }}>
            
            <Badge /> <Badge /> <Badge />
          </a>
        </Whisper>
      </Box>
    ) : null;

    return (
      <ul
        style={{
          padding: "0px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {moreItem}
      </ul>
    );
  };

  return (
    <Calendar
      bordered
      renderCell={renderCell}
      value={selectedDate}
      onChange={setSelectedDate}
      format="YYYY-MM"
    />
  );
};

export default function Progress() {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [statsData, setStatsData] = useState([]);
  const { getStorageItem } = Storage();
  const [user, setUser] = useState(getStorageItem("user"));
  const decodedToken = jwtDecode(user.token);
  const userIdFromToken = decodedToken.id;

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  const backgroundImages = [Images.background6];

  const GetStatsReport = async () => {
    const { result } = await ReportServices.getProgress(userIdFromToken);
    setStatsData(result);
  };

  useEffect(() => {
    GetStatsReport();
  }, []);

  const renderDay = (day, _value, DayComponentProps) => {
    const dateString = day.format("YYYY-MM-DD");
    const hasData = statsData.some((user) =>
      user.projects.some((project) =>
        project.days.some((date) => date.date === dateString)
      )
    );

    return (
      <Box sx={{ position: "relative" }}>
        <PickersDay {...DayComponentProps} day={day} />
        {hasData && (
          <Box
            sx={{
              position: "absolute",
              bottom: 6,
              left: "50%",
              transform: "translateX(-50%)",
              width: 328,
              height: 8,
              borderRadius: "50%",
              backgroundColor: Colors.primary,
            }}
          />
        )}
      </Box>
    );
  };

  const calculateTotalHours = (data, date) => {
    const month = dayjs(date).format("MM");
    const year = dayjs(date).format("YYYY");
    return data
      .flatMap((user) =>
        user.projects.flatMap((project) =>
          project.days
            .filter(
              (day) => dayjs(day.date).format("MM/YYYY") === `${month}/${year}`
            )
            .flatMap((day) => day.hour)
        )
      )
      .reduce((total, hours) => total + hours, 0)
      .toFixed(2);
  };

  return (
    <>
      <Box sx={{ mt: 5, ml: 2 }}>
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "600", color: Colors.primary }}
        >
          My Progress
        </Typography>
      </Box>
      <Box sx={{ mt: 5, ml: 2, mr: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    backgroundImage: `url(${Images.background1})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "100%",
                    height: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    color: "white",
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      textAlign: "center",
                    }}
                  >
                    This Month Total Progress
                  </Typography>
                  <Box sx={{ fontWeight: "bold", fontSize: "18px", mt: 1 }}>
                    {calculateTotalHours(statsData, selectedDate)}
                    {" hours"}
                  </Box>
                </Box>
              </Grid>

              {statsData.length > 0 &&
                statsData.map((user, userIndex) =>
                  user.projects.map((item, index) => {
                    const backgroundIndex =
                      (userIndex + index) % backgroundImages.length;
                    const totalHours = item.days
                      .filter(
                        (day) =>
                          dayjs(day.date).format("MM/YYYY") ===
                          dayjs(selectedDate).format("MM/YYYY")
                      )
                      .flatMap((day) => day.hour)
                      .reduce((acc, hour) => acc + hour, 0);

                    // Render project only if it has non-zero hours
                    if (totalHours > 0) {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          key={`${userIndex}-${index}`}
                        >
                          <Box
                            sx={{
                              backgroundImage: `url(${backgroundImages[backgroundIndex]})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              width: "100%",
                              height: "100px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                              color: "white",
                              borderRadius: "10px",
                            }}
                          >
                            <Typography
                              sx={{ fontWeight: "bold", fontSize: "15px" }}
                            >
                              {item.project_name.name}
                            </Typography>
                            <Box
                              sx={{
                                fontWeight: "bold",
                                fontSize: "18px",
                                mt: 1,
                              }}
                            >
                              {totalHours} hours
                            </Box>
                          </Box>
                        </Grid>
                      );
                    } else {
                      return null; // Skip rendering if hours are 0
                    }
                  })
                )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                background: Colors.white,
                borderRadius: "20px",
                boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CalendarComponent
                  handleDateClick={handleDateClick}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  statsData={statsData}
                  renderDay={renderDay}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
