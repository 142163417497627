export const ErrorHandler =  (error) => {
  if (error?.response) {
      console.log("Error Handler Call", error?.response?.status);
      if (error?.response?.status === 401) {
        
        localStorage.clear();
      
        window.location.href ="/login";
        

      }
      return error.response.data;
    } else if (error.request) {
      return error.message;
    } else {
      return "Something went wrongs";
    }
  };