import React, { Suspense, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "../Layout/Header";
import MenuIcon from "@mui/icons-material/Menu";
import { Colors } from "../Assets/Styles/Colors";
import SideNav from "../Layout/SideNav";
import UserServices from "../Api/UserServices/User.index";
import Storage from "../Utils/Storage";
import { jwtDecode } from "jwt-decode";
export const Layout = () => {
  const [toggleStatus, setToggleStatus] = useState(true);
  const [userDetail, setUserDetail] = useState([]);
  const { setStorageItem, getStorageItem } = Storage();

  const [user, setUser] = useState(getStorageItem("user"));
  const decodedToken = jwtDecode(user.token);
  const userIdFromToken = decodedToken.id;
  const userId = userIdFromToken;

  const handleGetUsers = async (page, limit, search,status   ) => {
    try {
      const { data, responseCode, message } = await UserServices.getUser(
        page,
        limit,
        search,
        status?status:""
      );
      const filteredUser =data?.output?.users.filter((user_id)=> user_id._id === userId)
      setUserDetail(filteredUser);
      console.log(filteredUser);
    
    } catch (error) {
      console.error("Error while fetching users:", error);
    }
  };
    // useEffect(() => {
    //   handleGetUsers(1, 1000, "","");
    // }, [setUserDetail]);

  return (
    <Box sx={{ bgcolor: "#FCFCFC" }}>
      {/* ========== Header ========== */}
      <Header handleGetUsers={handleGetUsers} userDetail={userDetail}/>

      <Box component="main" sx={{ display: "flex",height:"100vh" }}>
        {/* ========== Sidebar ========== */}
        <Box
          sx={{
            height: `calc(96.4vh)`,
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            zIndex:  "1111",
            overflow: "hidden",
            borderRight: `1px solid #EAEAEA`,
            width: {
              xs: toggleStatus ? "40px" : "43px",
              md: toggleStatus ? "260px" : "35px",
              sm: toggleStatus ? "260px" : "35px",
            },
            px: !toggleStatus ? 1.25 : 1.5,
            py: !toggleStatus ? 1 : 2,
            transition: "width 0.5s ease-in-out !important",
            visibility: toggleStatus ? "visible" : "visible",
            // bgcolor: "#FCFCFC",
            background:"  #FBBA1E",
            overflowY:"auto"
          }}
        >
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              float: "right",
              mt: 2,
              mr: 0,
             
            }}
          >
            {/* <MenuIcon
              onClick={() => setToggleStatus(!toggleStatus)}
              sx={{
                fontSize: "25px",
                cursor: "pointer",
                color: Colors.black,
                
                display: { xs: "none", sm: "flex", md: "flex" },
              }}
            /> */}
          </Box>
          <SideNav status={toggleStatus} />
        </Box>

        <Box
          sx={{
            mt: 6,
            // height: `calc(94.5vh)`,
            width: 1,
            position: "relative",
            overflowY: "auto",
            // background:Colors.silver
            background:"rgb(245 246 247)"
          }}
        >
          <Outlet handleGetUsers={handleGetUsers} userDetail={userDetail}/>
        </Box>
      </Box>
    </Box>
  );
};
