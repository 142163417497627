import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

export default function MultiSelectAutocomplete({
  names,
  personName,
  setPersonName,
  register,
  selectedUser,
}) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  // const filteredName = names.filter(
  //   (email) => email.role !== "admin" && email.role !== "company"
  // );

  useEffect(() => {
    if (
      selectedUser &&
      selectedUser.assignedUsers &&
      Array.isArray(selectedUser.assignedUsers)
    ) {
      const preselectedUsers = selectedUser.assignedUsers.map((user) => ({
        user: user?.user?._id,
        _id: user?._id,
      }));

      const selected = names.filter((name) =>
        preselectedUsers.some((users) => users.user === name._id)
      );

      setSelectedOptions(selected);
      setPersonName(
        // selected.map((user) => ({ _id: user._id, name: user.name }))
        selected.map((user) => ( user._id ))
      );
      console.log(selectedUser);
    } 
    else if (
      selectedUser &&
      selectedUser.projectIds &&
      Array.isArray(selectedUser.projectIds)
    ) {
      const preselectedUsers = selectedUser?.projectIds?.map((user) => ({
        name: user.name,
        _id: user._id,
      }));
      console.log(preselectedUsers);
      const selected = names.filter((name) =>
        preselectedUsers.some((users) => users._id === name._id)
      );
      setSelectedOptions(selected);
      setPersonName(
        selected.map((user) => ({ _id: user._id, name: user.name }))
        // selected.map((user) => ( user._id ))
      );
    }
  }, [selectedUser, names, setPersonName]);

  
  const handleChange = (event, value) => {
    setSelectedOptions(value);
    setPersonName(value.map((user) => ({ _id: user?._id, name: user?.name })));
    // setPersonName(value.map((user) => (user._id)));
  };

  return (
    <Autocomplete
      multiple
      options={names}
      value={selectedOptions}
      onChange={handleChange}
      getOptionLabel={(option) => option.email || option.name}
      getOptionSelected={(option, value) => option._id === value._id}
      renderInput={(params) => (
        <TextField
          sx={{ height: "59px", overflowY: "auto" }}
          {...params}
          variant="outlined"
          placeholder="Select"
          {...register}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <FormControlLabel
            control={<Checkbox checked={selected} />}
            label={option.email || option.name}
          />
        </li>
      )}
    />
  );
}
