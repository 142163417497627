import { ProjectRoutes } from "./Project.route";
import { post, get, put ,deleted,patch} from "../index";

const ProjectServices = {
  createProject: async (obj) => {
    let result = await post(ProjectRoutes.createProject, obj);
    return result;
  },
  getProject: async (page , limit , name ,assignedUsers ,priority,status) => {
    let result = get(ProjectRoutes.getProject +`?page=${page}&limit=${limit}&search=${name}&assignedUser=${assignedUsers}&priority=${priority}&status=${status}`);
    return result;
  },
  getProjectsWithHours: async (priority) => {
    let result = get(ProjectRoutes.getProjectsWithHours + `?priority=${priority}` );
    return result;
  },
  assignProject: async (obj) => {
    let result = put(ProjectRoutes.assignProject ,obj);
    return result;
  },
  updateProject: async (obj ,id) => {
    let result = put(ProjectRoutes.updateProject + `/${id}`,obj);
    return result;
  },
  deleteAssignedUser: async (id ,obj) => {
  
    let result =  deleted(ProjectRoutes.deleteAssignedUser + `/${id}`,obj);
    return result;
  },
  deleteProject: async (id) => {
    let result =  deleted(ProjectRoutes.deleteProject + `/${id}`);
    return result;
  },
};

export default ProjectServices;
