export const Images = {
  logo: require("./Logo.png"),
  defaultImage: require("./defaultImage.jpg"),
  login: require("./login.png"),
  background1: require("./Background 1.png"),
  background2: require("./Background 2.png"),
  background3: require("./Background 3.png"),
  background4: require("./Background 4.png"),
  background5: require("./Background 5.png"),
  background6: require("./Background 6.png"),
};
